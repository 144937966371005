/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { countryList } from 'components/RegForms/countryList';
import { BASE_URL } from 'config';
import { store } from 'store';
import {
	CLEAR_MSG,
	DOCUMENT_SUBMITTED,
	EMAIL_PASS,
	ERROR,
	HANDLE_BUSINESS_FIELD_CHANGE,
	HANDLE_CONTACT_FIELD_CHANGE,
	HANDLE_CURRENCY_CHANGE,
	LOG_IN,
	LOG_OUT,
	OFFER_SELECTED,
	REMOVE_HASH,
	RESET_STATE,
	SET_LOGIN_DATA,
	SHOPIFY_CONNECT,
	UPDATE_CLIENT,
	FACEBOOK_CONNECT,
	UPDATE_VALUE,
	UPDATE_OTP_TIMER,
} from 'store/types';
import * as publicIp from 'public-ip';
import { PAGE } from '../../constants';
import { message } from 'antd';
import * as Sentry from '@sentry/nextjs';

// auth actions
export const handleLogin =
	(email: string, password: string) => async (dispatch: any) => {
		try {
			const result = await axios.post(`${BASE_URL}auth/login`, {
				email,
				password,
			});
			return dispatch({
				type: EMAIL_PASS,
				payload: { hashkey: result.data.hashKey, email: result.data.sendTo },
			});
		} catch (err) {
			Sentry.captureException(err);
			Sentry.captureMessage('authAction -> handleLogin', err);

			if (err?.response?.status === 400) {
				err.response.data.message.forEach((error: string) => {
					message.error(error);
					return dispatch({
						type: ERROR,
						payload: err.response.data,
					});
				});
			} else {
				message.error(err.response.data.message);
				return dispatch({
					type: ERROR,
					payload: err.response.data,
				});
			}
		}
	};

export const removeHash = () => {
	try {
		return {
			type: REMOVE_HASH,
		};
	} catch (err) {
		Sentry.captureException(err);
		Sentry.captureMessage('authAction -> removeHash', err);

		return {
			type: ERROR,
			payload: err.response.data,
		};
	}
};

export const submitLogin = (otp: string) => async (dispatch: any) => {
	const { hashKey, loginData } = store.getState().auth;
	const { email } = loginData;
	const data = {
		sendTo: email,
		hash: hashKey,
		otp: otp,
		email: email,
		ip: await publicIp.v4(),
	};

	try {
		const result = await axios.post(`${BASE_URL}auth/login/verify-otp`, data);
		return dispatch({
			type: LOG_IN,
			payload: result.data,
		});
	} catch (err) {
		Sentry.captureException(err);
		Sentry.captureMessage('authAction -> submitLogin', err);

		return dispatch({
			type: ERROR,
			payload: err.response.data,
		});
	}
};

// Registration
export const contact_info_onChange = (e: any) => {
	try {
		return {
			type: HANDLE_CONTACT_FIELD_CHANGE,
			payload: e,
		};
	} catch (err) {
		Sentry.captureException(err);
		Sentry.captureMessage('authAction -> contact_info_onChange', err);

		return {
			type: ERROR,
			payload: err.response.data,
		};
	}
};

export const onChangeBusinessInfo = (e: any) => (dispatch: any) => {
	try {
		return dispatch({
			type: HANDLE_BUSINESS_FIELD_CHANGE,
			payload: e,
		});
	} catch (err) {
		Sentry.captureException(err);
		Sentry.captureMessage('authAction -> onChangeBusinessInfo', err);

		return dispatch({
			type: ERROR,
			payload: err.response.data,
		});
	}
};

export const onChangeCountry = (country: string) => (dispatch: any) => {
	countryList.map((data) => {
		if (country === data.country) {
			try {
				return dispatch({
					type: HANDLE_CURRENCY_CHANGE,
					payload: data,
				});
			} catch (err) {
				Sentry.captureException(err);
				Sentry.captureMessage('authAction -> onChangeCountry', err);

				return dispatch({
					type: ERROR,
					payload: err.response.data,
				});
			}
		}
	});
};

export const updateValue = (key: string, value: string | number) => {
	return {
		type: UPDATE_VALUE,
		payload: {
			key,
			value,
		},
	};
};

export const updateOtpTimer = (second: number) => {
	return {
		type: UPDATE_OTP_TIMER,
		payload: second,
	};
};

export const setPage =
	(page: string) => async (dispatch: any, getState: any) => {
		const { page: currentPage } = await getState().auth;
		await dispatch(updateValue('page', page));
		await dispatch(updateValue('previousPage', currentPage));
	};

export const offer_selected = (offerId: string) => {
	try {
		return {
			type: OFFER_SELECTED,
			payload: offerId,
		};
	} catch (err) {
		Sentry.captureException(err);
		Sentry.captureMessage('authAction -> offer_selected', err);

		return {
			type: ERROR,
			payload: err.response.data,
		};
	}
};

export const register_client = () => async (dispatch: any, getState: any) => {
	const authData: any = store.getState().auth;

	const phone = `${authData.contactInfo.phone_prefix}${authData.contactInfo.phone}`;
	const data = {
		...authData.contactInfo,
		website: authData.businessInfo.website,
		companyName: authData.businessInfo.companyName,
		regCountry: authData.businessInfo.regCountry,
		businessInfo: {
			revMonth: parseFloat(
				authData.businessInfo.revMonth.toString().replace(/,/g, '')
			),
			reqAdvanceAmount: parseFloat(
				authData.businessInfo.reqAdvanceAmount.toString().replace(/,/g, '')
			),
			businessType: authData.businessInfo.businessType,
		},
		phone: phone,
		agreement: true,
		ip: await publicIp.v4(),
	};
	try {
		const result = await axios.post(`${BASE_URL}auth/registration`, data);
		const { page: currentPage } = await getState().auth;
		return await Promise.all([
			dispatch({
				type: RESET_STATE,
				payload: result.data.passEligibilityTest,
			}),
			dispatch(updateValue('page', PAGE.THANKS_PAGE)),
			dispatch(updateValue('previousPage', currentPage)),
		]);
	} catch (err) {
		Sentry.captureException(err);
		Sentry.captureMessage('authAction -> register_client', err);

		return dispatch({
			type: ERROR,
			payload: err.response.data,
		});
	}
};

export const clearMsg = () => {
	return {
		type: CLEAR_MSG,
	};
};

export const checkLoginData = () => async (dispatch: any) => {
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const res = await axios.get(`${BASE_URL}client/info`, options);
		return dispatch({
			type: SET_LOGIN_DATA,
			payload: res.data,
		});
	} catch (err) {
		Sentry.captureException(err);
		Sentry.captureMessage('authAction -> checkLoginData', err);

		if (err?.response?.status === 401) {
			return dispatch({
				type: LOG_OUT,
				payload: err,
			});
		}
	}
};

export const handleShopifyConnect = () => async (dispatch: any) => {
	try {
		return dispatch({
			type: SHOPIFY_CONNECT,
		});
	} catch (err) {
		Sentry.captureException(err);
		Sentry.captureMessage('authAction -> handleShopifyConnect', err);

		return dispatch({
			type: ERROR,
			payload: err.response.data,
		});
	}
};

export const set_document_submitted =
	(newState: string) => async (dispatch: any) => {
		const data = {
			newState,
		};
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		try {
			await axios.post(`${BASE_URL}client/current-state`, data, options);
			return dispatch({
				type: DOCUMENT_SUBMITTED,
			});
		} catch (err) {
			Sentry.captureException(err);
			Sentry.captureMessage('authAction -> set_document_submitted', err);

			return dispatch({
				type: ERROR,
				payload: err.response.data,
			});
		}
	};

export const handleLogout = () => async (dispatch: any) => {
	return dispatch({
		type: LOG_OUT,
	});
};

export const updateClientCurrentState =
	(currentState: string, force: boolean) => async (dispatch: any) => {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const data = {
			newState: currentState,
			force,
		};
		try {
			const res = await axios.post(
				`${BASE_URL}client/current-state`,
				data,
				options
			);
			return dispatch({
				type: UPDATE_CLIENT,
				payload: res.data.currentState,
			});
		} catch (err) {
			Sentry.captureException(err);
			Sentry.captureMessage('authAction -> updateClientCurrentState', err);

			return dispatch({
				type: ERROR,
				payload: err.response.data,
			});
		}
	};

export const handleFacebookConnect = () => async (dispatch: any) => {
	try {
		return dispatch({
			type: FACEBOOK_CONNECT,
		});
	} catch (err) {
		Sentry.captureException(err);
		Sentry.captureMessage('authAction -> handleFacebookConnect', err);

		return dispatch({
			type: 'Error',
			payload: err,
		});
	}
};

export const newUpdateClientCurrentState =
	(currentState: string) => async (dispatch: any) => {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const data = {
			newState: currentState,
		};
		try {
			const res = await axios.post(
				`${BASE_URL}client/current-state`,
				data,
				options
			);
			return dispatch({
				type: UPDATE_CLIENT,
				payload: res.data.currentState,
			});
		} catch (err) {
			Sentry.captureException(err);
			Sentry.captureMessage('authAction -> newUpdateClientCurrentState', err);

			return dispatch({
				type: ERROR,
				payload: err.response.data,
			});
		}
	};
