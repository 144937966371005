export const countryList = [
	{ country: 'Austria', currency: 'EUR' },
	{ country: 'Australia', currency: 'AUD' },
	{ country: 'Belgium', currency: 'EUR' },
	{ country: 'Denmark', currency: 'DKK' },
	{ country: 'Finland', currency: 'EUR' },
	{ country: 'France', currency: 'EUR' },
	{ country: 'Germany', currency: 'EUR' },
	{ country: 'Greece', currency: 'EUR' },
	{ country: 'Hong Kong', currency: 'HKD' },
	{ country: 'Ireland', currency: 'EUR' },
	{ country: 'Italy', currency: 'EUR' },
	{ country: 'Luxembourg', currency: 'EUR' },
	{ country: 'Netherlands', currency: 'EUR' },
	{ country: 'Norway', currency: 'NOK' },
	{ country: 'Portugal', currency: 'EUR' },
	{ country: 'Spain', currency: 'EUR' },
	{ country: 'Sweden', currency: 'SEK' },
	{ country: 'Switzerland', currency: 'CHF' },
	{ country: 'United Kingdom', currency: 'GBP' },
	{ country: 'United States', currency: 'USD' },
];
