/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { BASE_URL } from 'config';
import {
	CHECK_FINANCIAL,
	CHECK_KYC,
	GET_ALL_ADVANCE,
	GET_CLIENT,
	GET_CONTRACT,
	LOG_OUT,
	OFFER_SELECTION_DONE,
	SET_LOADING,
	SET_OFFER,
	STRIPE_CONNECT,
	SET_REPAYMENT_GATEWAY,
	SET_REPAYMENT_GATEWAY_STRING,
} from 'store/types';
import { store } from '../index';

// get client info
export const getClient = () => async (dispatch: any) => {
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
				'Content-Type': 'application/json',
			},
		};

		const res = await axios.get(
			`${BASE_URL}client/${store.getState().auth.loginData.clientId}`,
			options
		);

		return dispatch({
			type: GET_CLIENT,
			payload: res.data,
		});
	} catch (err) {
		if (err.reponse.statusCode === 401)
			return dispatch({
				type: LOG_OUT,
			});
	}
};

export const getContract = (offerId: string) => async (dispatch: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const data = {
			clientId: store.getState().auth.loginData.clientId,
			fileType: 'contract',
		};
		const res = await axios.get(
			`${BASE_URL}file/list?limit=5&offerId=${offerId}&page=1&fileType=contract`,
			options
		);
		return dispatch({
			type: GET_CONTRACT,
			payload: res,
		});
	} catch (err) {
		return dispatch({
			type: 'Error',
			payload: err,
		});
	}
};

export const updateStripeStatus = () => {
	return {
		type: STRIPE_CONNECT,
	};
};

export const setLoading = (status: boolean) => (dispatch: any) => {
	try {
		return dispatch({
			type: SET_LOADING,
			payload: status,
		});
	} catch (error) {
		return {
			type: 'Error',
			payload: error,
		};
	}
};

export const set_selected_offer = (offer: any) => (dispatch: any) => {
	try {
		return dispatch({
			type: SET_OFFER,
			payload: offer,
		});
	} catch (error) {
		return {
			type: 'Error',
			payload: error,
		};
	}
};

export const set_offer_selected_done = () => (dispatch: any) => {
	try {
		return dispatch({
			type: OFFER_SELECTION_DONE,
		});
	} catch (error) {
		return {
			type: 'Error',
			payload: error,
		};
	}
};

export const getAdvanceAllData = () => async (dispatch: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.get(
			`${BASE_URL}advance/client-advance-list`,
			options
		);
		return dispatch({
			type: GET_ALL_ADVANCE,
			payload: result.data,
		});
	} catch (err) {
		return err;
	}
};

export const clearData = () => {
	try {
		return {
			type: LOG_OUT,
		};
	} catch (error) {
		return {
			type: 'Error',
			payload: error,
		};
	}
};

export const setCheckKYC = (check: boolean) => {
	try {
		return {
			type: CHECK_KYC,
			payload: check,
		};
	} catch (error) {
		return {
			type: 'Error',
			payload: error,
		};
	}
};

export const setCheckFinancial = (check: boolean) => {
	try {
		return {
			type: CHECK_FINANCIAL,
			payload: check,
		};
	} catch (error) {
		return {
			type: 'Error',
			payload: error,
		};
	}
};

export const setRepaymentGateway = (repaymentGateway: any[]) => {
	try {
		return {
			type: SET_REPAYMENT_GATEWAY,
			payload: repaymentGateway,
		};
	} catch (error) {
		return {
			type: 'Error',
			payload: error,
		};
	}
};

export const setRepaymentGatewayString = (
	gateway: string,
	advanceId: string
) => {
	try {
		return {
			type: SET_REPAYMENT_GATEWAY_STRING,
			payload: { gateway, advanceId },
		};
	} catch (error) {
		return {
			type: 'Error',
			payload: error,
		};
	}
};
